import { useRouter } from "next/router";
import { Form } from "react-bootstrap";
import { POST } from "../../utils/requests";

const FormSubmit = ({ url, next, children, callback, ...props }) => {
  const router = useRouter();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const x = await POST(url, new FormData(e.target));
    if (x?.status && next) router.push(next);
    if (x?.status && callback) callback(x.data);
  };

  return (
    <Form onSubmit={HandleSubmit} {...props}>
      {children}
    </Form>
  );
};

export default FormSubmit;
